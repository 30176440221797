* {
  font-family: "JetBrains Mono", monospace;
}

/* App Itself */
.App {
  background-color: #36393f;
  min-height: 100vh;
  font-size: 2rem;
  color: white;
  justify-content: center;
}

/* Buttons */
button {
  color: white;
  background-color: #00ff90;
  border-style: none;
  border-radius: 1rem;
  font-size: 2.1rem;
  transition: 0.4s;
  cursor: pointer;
}

button:hover {
  filter: brightness(80%);
}

button:disabled {
  filter: brightness(65%);
}

/* Navigation Bar */
.nav {
  background-color: #212326;
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 2.19rem;
}

span#credits {
  font-size: 1rem;
  color: #36393e;
}

button#info {
  height: 3rem;
  width: 3rem;
  margin-left: auto;
  color: #212326;
  font-weight: 900;
}

a {
  color: #ffffff;
  text-decoration: none;
}

/* Info Box */
.info-div {
  display: none;
}

.info-box {
  width: 37vh;
  color: #ffffff;
  background: #16181c;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: left;
  font-size: 1.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
}

img#github-repo {
  border-radius: 0.5rem;
  height: 3rem;
}

p#title {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
}

.info-close-button {
  font-size: 1.6rem;
  border-radius: 0.5rem;
  margin-top: 0.8rem;
  color: #16181c;
  font-weight: 700;
  padding: 0.45rem 1rem;
}

/* App Contents */
.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

/* Start Content */
span.start-text {
  color: #00ff90;
}

.upload-image-button {
  color: #36393f;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img.start-image-1,
img.start-image-2 {
  width: 14.4rem;
}

.start-stack-divs {
  overflow: hidden;
  display: flex;
}

.start-stacking-divs {
  float: left;
}

.start-imgdiv-1 {
  padding-right: 2rem;
}

.start-text-div {
  max-width: 33rem;
}

.hide {
  display: none;
}

/* Editor Content */
div#editor {
  display: none;
}

span.editor-text {
  color: #00ff90;
  font-size: 1.4rem;
  display: inline-block;
}

.editor-canvas {
  image-rendering: optimizeSpeed;
  /* Older versions of FF          */
  image-rendering: -moz-crisp-edges;
  /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast;
  /* Safari                        */
  image-rendering: -o-crisp-edges;
  /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated;
  /* Awesome future-browsers       */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE                            */
  transform: scale(13);
  transform-origin: left top;
}

span.editor-input-label {
  font-size: 1.2rem;
  color: #ced1d5;
}

.invert-input {
  direction: rtl;
}

.editor-range-input {
  -webkit-appearance: none;
  width: 120%;
  height: 1rem;
  border-radius: 1rem;
  background: #efefef;
  outline: none;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}

.editor-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background: #04aa6d;
  cursor: pointer;
}

.editor-grid-top {
  grid-area: header;
}

.editor-grid-left {
  grid-area: sliders;
  margin-top: -1rem;
  width: 17rem;
}

.editor-grid-right {
  grid-area: preview;
  width: 9rem;
}

.editor-grid {
  display: grid;
  grid-template-areas:
    "header header"
    "sliders preview";
  gap: 0.5rem;
}

.editor-finish-button {
  color: #36393f;
  font-weight: 600;
  width: 20.5rem;
  margin-top: 1.1rem;
}

.advanced-dropdown-bg {
  width: 20.5rem;
  margin-top: 0.6rem;
  border-radius: 1rem;
  background-color: #292b30;
}

.advanced-dropdown-div {
  display: none;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
}

.advanced-button {
  color: #36393f;
  font-weight: 600;
  width: 20.5rem;
  font-size: 1.6rem;
  padding: 0.4rem 0rem 0.4rem 0rem;
  background-color: #52bbff;
}

.clr-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 55px;
  height: 27px;
}

.clr-input::-webkit-color-swatch {
  border-radius: 1rem;
  border: none;
}
.editor-aspect-ratio-size-control-div {
  display: none;
}

.editor-precise-div {
  width: 20.5rem;
  margin-top: -1rem;
  display: none;
  margin-bottom: 0.5rem;
}

.editor-precise-button {
  color: #36393f;
  padding: 0rem 1.3rem;
  font-size: 1rem;
  font-weight: 800;
  background-color: #04aa6d;
}

.editor-precise-left-button {
  margin-left: 14rem;
}

.editor-label-stack {
  width: 20.5rem;
}

.editor-reset-btn {
  font-size: 1.1rem;
  margin-top: 0.8rem;
  color: #36393f;
  background-color: #e3505f;
  font-weight: 900;
  float: right;
}

/* Finish Content */
div#finish {
  display: none;
  text-align: center;
}

img.finish-img {
  width: 18rem;
  image-rendering: pixelated;
}

.finish-download-button {
  color: #36393f;
  font-weight: 600;
  margin-top: 1rem;
}

.finish-upload-button {
  color: #36393f;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  background-color: #ff9b61;
}

canvas#finish-canvas {
  display: none;
}

/* Mobile CSS */
.mobile-btn-seperator-div {
  display: none;
}

@media only screen and (max-device-width: 602px) {
  .finish-upload-button {
    margin-left: 0rem;
  }

  .mobile-btn-seperator-div {
    display: block;
  }
}

@media only screen and (max-device-width: 520px) {
  img.start-image-1,
  img.start-image-2 {
    width: 10rem;
  }
}

@media only screen and (max-device-width: 580px) {
  .editor-grid-top {
    grid-area: header;
  }

  .editor-grid-left {
    grid-area: sliders;
    margin-top: -1rem;
    width: 17rem;
  }

  .editor-grid-right {
    grid-area: preview;
  }

  .editor-grid {
    display: grid;
    grid-template-areas:
      "header header"
      "sliders sliders"
      "preview preview";
    gap: 0.5rem;
  }

  .editor-canvas {
    image-rendering: optimizeSpeed;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
    transform: scale(17);
    -webkit-transform-origin-x: -0.12rem;
    -webkit-transform-origin-y: 0;
    padding-bottom: 0.1rem;
  }
}
